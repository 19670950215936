/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";



ion-modal {
	--min-height: calc(100vh - 88px);
}


body::-webkit-scrollbar-track
{
	-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
	background-color: #F5F5F5;
}

body::-webkit-scrollbar
{
	width: 6px;
	background-color: #F5F5F5;
}

body::-webkit-scrollbar-thumb
{
	background-color: #000000;
}


body {
	background-color: whitesmoke;
}

textarea {
	background: lightyellow !important;
}

input {
	// background: lightyellow !important;
	// padding-left: 14px !important;
	// // padding-top: 0px !important;
    // // padding-bottom: 0px !important;
	// padding-bottom: 12px !important;
    // padding-top: 12px !important;
}

.label-stacked {
	margin-bottom: 6px !important;
}

.happ-tank-container {

	width: 40px;
	height: 50px;
	margin-right: 12px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;border-radius: 6px;
	border: 1px solid gray;

	.happ-tank-inner {
		position: absolute;
		bottom: 0px;
		left: 0px;
		width: 38px;
		border-radius: 4px;
		background: lightblue;
	}
}	

.happ-subpage-header {
	background-color: var(--ion-color-primary);
	color: white;
	padding: 14px;

	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	h2 {
		margin: 0px;
	}

}


.happ-list-container {
	h2 {
		font-weight: 700;
	}
}


.alert-button-group {
	justify-content: space-between !important;
}

.button-large {
	text-wrap: wrap;
}

.happ-list-desc {
	margin-left: 20px; 
	font-size: 12px; 
	color: gray;
	margin-top: 0px;
	margin-right: 8px;
}

.happ-list-top-desc {
	margin-left: 20px; 
	font-size: 12px; 
	color: gray;
	margin-top: 0px;
	margin-right: 8px;

	margin-top: 20px;
    text-align: justify;
    margin-right: 24px;
    border-bottom: 1px solid lightgray;
    padding-bottom: 12px;
    margin-bottom: 0px;
}

ion-item.happ-input-item-container {
	--background: lightyellow;
}


.happ-input-item-container {
	margin-bottom: 12px;
}